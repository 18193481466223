@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
:root {
  --header-background-color: #007477;
  --header-text-color: white;
  --message-background-color: #007477;
  --message-text-color: white;
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden !important;
  font-family: Poppins, sans-serif;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
#citibot_support_chat .rcw-widget-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: auto;
}
#citibot_support_chat .rcw-conversation-container {
  width: calc(100% - 20px);
  min-width: calc(100% - 20px);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px 1px #b5b5b5;
  margin: 10px;
}
#citibot_support_chat .rcw-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: var(--header-background-color);
  padding: 15px;
}
#citibot_support_chat .rcw-header .avatar {
  width: 45px;
  height: 45px;
  margin-right: 0;
}
#citibot_support_chat .rcw-header .rcw-title {
  color: var(--header-text-color);
  font-size: 18px;
  margin: 0;
  padding: 0;
  line-height: 1.3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#citibot_support_chat .rcw-header span {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
#citibot_support_chat .rcw-header div {
  max-width: calc(100% - 45px);
}
#citibot_support_chat .rcw-close-button {
  display: none;
}
#citibot_support_chat .rcw-messages-container {
  max-height: calc(100vh - 330px);
  overflow-y: auto;
  padding: 0;
  overflow-x: hidden;
  min-height: calc(100vh - 330px);
}
#citibot_support_chat .rcw-messages-container .rcw-message {
  margin: 8px;
  display: flex;
  gap: 8px;
}
#citibot_support_chat .rcw-messages-container .rcw-message img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin: 0;
}
#citibot_support_chat .rcw-messages-container .rcw-message .rcw-timestamp {
  font-size: 10px;
  margin-top: 5px;
}
#citibot_support_chat .rcw-messages-container .rcw-message.rcw-message-client {
  flex-direction: row-reverse;
}
#citibot_support_chat .rcw-message-text {
  max-width: 210px;
}
#citibot_support_chat .rcw-message-text.rcw-message-image-box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}
#citibot_support_chat .rcw-message-text a {
  color: var(--header-text-color);
}
#citibot_support_chat
  .rcw-messages-container
  .rcw-message.rcw-message-client
  .rcw-message-text {
  background-color: #a3eaf7;
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}
#citibot_support_chat
  .rcw-messages-container
  .rcw-message.rcw-message-client
  .rcw-message-text
  p {
  margin: 0;
}
#citibot_support_chat .rcw-messages-container .rcw-response .rcw-message-text {
  background-color: var(--message-background-color);
  color: var(--message-text-color);
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
  position: relative;
}
#citibot_support_chat
  .rcw-messages-container
  .rcw-response
  .rcw-message-text
  article {
  background-color: #fff;
  padding: 10px;
  border-radius: 12px;
  margin-top: 10px;
}
#citibot_support_chat
  .rcw-messages-container
  .rcw-response
  .rcw-message-text
  article
  .article__title {
  margin: 0;
  font-size: 12px;
  color: #000;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
#citibot_support_chat
  .rcw-messages-container
  .rcw-response
  .rcw-message-text
  .speak__icon {
  position: absolute;
  right: -30px;
  bottom: 12px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  z-index: 1;
}
#citibot_support_chat
  .rcw-messages-container
  .rcw-response
  .rcw-message-text
  .speak__icon
  img {
  width: 18px;
  height: 18px;
}
#citibot_support_chat .rcw-sender {
  display: flex;
  background-color: #f4f7f9;
  align-items: center;
  padding: 8px 10px;
}
#citibot_support_chat .rcw-sender .rcw-new-message {
  flex-grow: 1;
  padding: 0;
}
#citibot_support_chat .rcw-sender .rcw-new-message .rcw-input {
  font-size: 16px;
  background-color: #fff;
  border-radius: 5px;
  padding: 8px 5px;
  max-height: 24px;
}
#citibot_support_chat .rcw-sender .rcw-new-message .rcw-input:focus {
  outline: none;
}
#citibot_support_chat
  .rcw-sender
  .rcw-new-message
  .rcw-input[placeholder]:empty:before {
  color: gray;
  content: attr(placeholder);
}
#citibot_support_chat .rcw-sender .rcw-send,
#citibot_support_chat .rcw-sender .rcw-send {
  border: none;
  cursor: pointer;
  max-width: 37px;
  max-height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
#citibot_support_chat .rcw-sender .rcw-send .rcw-send-icon,
#citibot_support_chat .rcw-sender .rcw-send .rcw-send-icon-disabled {
  width: 25px;
  height: 25px;
}
#citibot_support_chat .custom-message-button {
  display: flex;
  background-color: #f4f7f9;
  align-items: center;
  padding: 0 10px 10px;
  flex-direction: column;
  gap: 5px;
}
#citibot_support_chat .custom-message-button button {
  border: none;
  border-radius: 3px;
  box-shadow: 0 2px 10px #00000080;
  font-size: 13px;
  padding: 8px 12px;
  text-transform: capitalize;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}
#citibot_support_chat .custom-message-button button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 10px #00000080;
}
#citibot_support_chat .custom-message-button a {
  font-size: 15px;
}
#citibot_support_chat .custom-message-button .powered-by .p-span {
  color: grey;
  text-decoration: underline;
  margin-right: 5px;
}
#citibot_support_chat .custom-message-button .powered-by .c-span {
  color: var(--header-background-color);
  font-weight: 700;
  text-decoration: underline;
}
#citibot_support_chat .launcherBtn {
  padding: 0;
  color: white;
  cursor: pointer;
  max-width: 100%;
  min-width: 100%;
  overflow: hidden;
  border: none;
  background: none;
  display: flex;
  justify-content: center;
}
#citibot_support_chat .launcherImg {
  width: 100%;
}
#citibot_support_chat .launcherImg img {
  width: 100%;
  height: 100%;
}
.loader {
  display: none;
  margin: 10px;
}
.loader.active {
  display: flex;
}
.loader-container {
  background-color: #f4f7f9;
  border-radius: 10px;
  max-width: 215px;
  padding: 15px;
  text-align: left;
}
.loader-dots {
  animation: bounce 0.5s ease infinite alternate;
  background: gray;
  border-radius: 50%;
  display: inline-block;
  height: 4px;
  margin-right: 2px;
  width: 4px;
}
.loader-dots:first-child {
  animation-delay: 0.2s;
}
.loader-dots:nth-child(2) {
  animation-delay: 0.3s;
}
.loader-dots:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(5px);
  }
}
#citibot_support_chat .quick-buttons-container {
  padding: 10px 0 0;
}
#citibot_support_chat .quick-buttons-container .quick-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
#citibot_support_chat
  .quick-buttons-container
  .quick-buttons
  .quick-list-button {
  align-items: center;
  display: flex;
  gap: 10px;
}
#citibot_support_chat
  .quick-buttons-container
  .quick-buttons
  .quick-list-button
  .quick-button {
  min-width: 155px;
  max-width: 210px;
}
#citibot_support_chat
  .quick-buttons-container
  .quick-buttons
  .quick-list-button
  .quick-button.rcw-quick-button-not-selected {
  border-color: rgba(16, 16, 16, 0.3);
  cursor: default;
  user-select: none;
  pointer-events: none;
}
#citibot_support_chat
  .quick-buttons-container
  .quick-buttons
  .quick-list-button
  .quick-button.rcw-quick-button-selected {
  background-color: #35cce6;
  color: #fff;
  cursor: default;
  user-select: none;
  pointer-events: none;
}
#citibot_support_chat
  .quick-buttons-container
  .quick-buttons
  .quick-list-button
  .quick-button
  .rcw-quick-button-text {
  text-transform: capitalize;
  white-space: pre-wrap;
}
#citibot_support_chat
  .quick-buttons-container
  .quick-buttons
  .quick-list-button
  .rcw_speak__icon {
  background-color: transparent;
  border: none;
  padding: 0;
}
#citibot_support_chat
  .quick-buttons-container
  .quick-buttons
  .quick-list-button
  .rcw_speak__icon
  img {
  width: 18px;
  height: 18px;
}

#citibot_support_chat.iframe-expanded .rcw-messages-container {
    max-height: calc(100vh - 305px);
    min-height: calc(100vh - 305px);
    overflow-y: auto;
    padding: 0;
    overflow-x: hidden;
}

#citibot_support_chat .rcw-close-widget-container{
  width: 100% !important;
}